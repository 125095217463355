import { Container, ContainerProps } from "@mui/material";
import React from "react";
import { Background } from "src/assets";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;
  return (
    <Container
      {...containerProps}
      sx={styles.root}
      maxWidth="sm"
    >
      {children}
    </Container>
  );
};

const styles = createStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    boxShadow: `0px 0px 22px 5px rgba(0,0,0,0.37)`,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#a8f4ff",
    overflow: "auto",
  },
});

export default Content;
