import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "https://m.m555m.com/";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const BTN_LOGIN_URL = "https://m.m555m.com/";
export const BTN_REGISTER_URL = "https://m.m555m.com/?action=register";

export const DOMAINS_USE_STATIC_URL = [
  "moo268.com",
  "moo555268.com",
  "moo555555.com",
  "moo5555555.com",
  "moo55555555.com",
  "moo666.com",
  "moo9898.com",
];

export const LOGIN_URL_MAP = {
  "moo555.fun": "https://a.vtrk8.com/dd07b987-0264-4eaa-b6ec-6da638ebb7eb",
};

export const REGISTER_URL_MAP = {
  "moo555.fun": "https://a.vtrk8.com/c6215ca9-190a-4e0d-9733-73ea63506b9d",
};

export * from "./codes";
export * from "./context";
